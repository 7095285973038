import React from 'react';
import { graphql } from 'gatsby';

import Layout from "../components/layout";
import PageTitle from "../components/page-title";
import SEO from "../components/seo";
import ImageGallery from "../components/image-gallery";

export default function CaseStudyTemplate({ data, location }) {
    const caseStudy = data.page;
    const description = caseStudy.frontmatter.description;
    const summary = caseStudy.frontmatter.summary;
    const title = caseStudy.frontmatter.title;

    const { page } = data;
    const { html } = page;

    const galleryImages = data.slides.edges.map(obj => {
        let rObj = {};
        rObj.image = obj.node.frontmatter.image.childImageSharp.fluid;
        rObj.caption = obj.node.frontmatter.caption;
        return rObj
    });

    return (
        <Layout location={location}>
            <SEO description={description}
                 title={title} />
           
            <PageTitle summary={summary}
                       title={title} />
           
            <ImageGallery data={galleryImages} containerClasses="mb-10 lg:mb-16"/>
           
            <div className={"content-wrap"}>
                <div className={"body-wrap"} dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($pathSlug: String!, $slidesPath: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
            html
            frontmatter {
                description
                summary
                title
            }
        }
        slides: allMarkdownRemark(filter: { fileAbsolutePath: {
            glob: $slidesPath
        } }) {
          edges {
            node {
              frontmatter {
                image {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                caption
              }
            }
          }
        }
    }
`;
